import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  inject,
  Input,
  OnDestroy,
  PLATFORM_ID,
  Renderer2
} from '@angular/core';
import {SharedModule} from "../../shared/shared.module";
import {isPlatformBrowser, NgOptimizedImage, ViewportScroller} from "@angular/common";
import {RouterLink} from "@angular/router";
export enum MenuItem {
  BurgerMenu = 'BURGER_MENU',
  Expertises = 'EXPERTISES',
  References = 'REFS',
  Contact = 'CONTACT',
  Nous = 'NOUS'
}

interface MenuState {
  isExpertise: boolean;
  isNous: boolean;
  isReference: boolean;
  isContact: boolean;
}
@Component({
  standalone: true,
  imports: [SharedModule, NgOptimizedImage, RouterLink],
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements AfterViewInit, OnDestroy{
  constructor(private  element: ElementRef, private  renderer: Renderer2 , @Inject(PLATFORM_ID) private  platform : Object ,     private viewportScroller: ViewportScroller,) {}
  menuState: MenuState = {
    isExpertise: false,
    isNous: false,
    isReference: false,
    isContact: false
  };
  private scrollListener: (() => void) | undefined;

  private scrollPosition: number = 0;
  isMobileMenuClosed =false ;
  MenuItem= MenuItem ;
  @Input() isHomePage = true;
  HandleClick(action :string) {
    switch (action){
      case "BURGER_MENU":
        this.mobileResetSettings();
        setTimeout(()=>{
          this.isMobileMenuClosed=!this.isMobileMenuClosed ;
        },0.3)
        break
      case "EXPERTISES":
        this.mobileResetSettings();
        this.menuState.isExpertise =true;
        break;
      case "REFS":
        this.mobileResetSettings();
        this.menuState.isReference=true;
        break;
      case 'CONTACT':
        this.mobileResetSettings();
        this.menuState.isContact=true;
        break;
      case 'NOUS':
        this.mobileResetSettings();
        this.menuState.isNous=true;
        break;
      default:
        break
    }
  }
  mobileResetSettings(): void {
    this.menuState.isExpertise = false;
    this.menuState.isNous = false;
    this.menuState.isReference = false;
    this.menuState.isContact = false;
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platform)) {
      this.scrollListener = this.renderer.listen('window', 'scroll', () => this.onWindowScroll());
      // this.adjustHeaderClass();
    }
  }
  private adjustHeaderClass(): void {
    const [x, y] = this.viewportScroller.getScrollPosition();
    const headerElement = this.element.nativeElement.querySelector('.ul');
    if(!headerElement) return ;
    if (y > 0) {
      this.renderer.addClass(headerElement, 'scrolled');
    } else {
      this.renderer.removeClass(headerElement, 'scrolled');
    }
  }
  private onWindowScroll(): void {
    this.adjustHeaderClass();
  }
  ngOnDestroy(): void {
    if (this.scrollListener) {
      this.scrollListener();
    }
  }
}
