<header>
  <app-header [isHomePage]="isSpecialRoute"></app-header>
</header>
<main class="main" >
  <router-outlet></router-outlet>
</main>

<footer>
  <app-footer [isHomePage]="isSpecialRoute" [route]="currentRoute"></app-footer>
</footer>
