<div *tablet-mobile-template class="wrapper">
      <div class="center" >
        <img class="logo" src="assets/images/logo.png" alt="Logo YellowIt" >
      </div>
      <div class="center email pointer">
        <a  style="color: #213B8F"  href="mailto:contact@yellow-it.fr" >contact&#64;yellow-it.fr</a>
      </div>
  <div style="text-align: center;margin-top: 0.5rem"> <span class="dot" style="font-size: 30px">  •  </span></div>
  <div class="center number">
    <span>+33</span> <span>6</span> <span>98</span> <span>29</span> <span>72</span> <span>17</span>
  </div>
  <div style="text-align: center;margin-top: 0.5rem"> <span class="dot" style="font-size: 30px">  •  </span></div>
  <div class="center address Montserrat">
          41 rue Camille <br> Desmoulins 92130 <br> Issy-les-Moulineaux
  </div>

  <div class="legal">
    <strong> {{ date }} &#169; Yellow IT Consulting </strong>
  </div>
</div>
<div *web-template [ngClass]="{'overflow' : !isHomePage}" class="wrapper_web safari_only">
  @if (isHomePage){
    <img style="z-index: -1"  class="img2" src="assets/images/home/web_waves.png" alt="Nuages"  >
  }
  <div class="logo">
    @if(!isWhiteLogo){
      <img ngSrc="assets/images/logo.png" alt="Logo YellowIt" height="57" width="250">
    }@else{
      <img src="assets/images/whitelogo.png" alt="Logo YellowIt" width="100%">
    }
  </div>
  <div class="banner">
    <img style="z-index: 0" class="img" src="/assets/images/footer/newfooter.png" alt="footer" >
    <div class="details">
        <div class="email pointer inline_block">
          <a style="color: #213B8F"  href="mailto:contact@yellow-it.fr" >contact&#64;yellow-it.fr</a>
        </div>
        <div class="number inline_block">
          <span class="dot" >  •  </span>
          <span>+33</span> . <span>6</span> . <span>98</span> .<span>29</span> .<span>72</span> .<span>17</span>
        </div>
        <div class="address inline_block">
          <span class="dot">  •  </span>
          41 rue Camille Desmoulins 92130 <br>Issy-les-Moulineaux
        </div>
        <div class="legal">
          <strong> {{ date }} &#169;
            Yellow IT Consulting </strong>
        </div>
      </div>
  </div>

</div>
