import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {NavigationEnd, Router, UrlSerializer} from "@angular/router";
import {filter} from "rxjs";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {MetaService} from "./services/seo/meta.service";
import {isPlatformServer} from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent  implements OnInit{
  currentRoute ='/'
  isSpecialRoute =true ;
  constructor(private router: Router , private  metaService :MetaService ,   @Inject(PLATFORM_ID) private platformId: Object ,    private urlSerializer: UrlSerializer) {
    if(isPlatformServer(this.platformId)) {
      this.metaService.createCanonicalURL();

    }

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).pipe(takeUntilDestroyed())
      .subscribe(({url}: any) => {
        this.currentRoute = url ;
        this.isSpecialRoute = this.currentRoute =='/';
        const cleanUrl = this.getCleanUrl(url);
        if(isPlatformServer(this.platformId) && url != "/PageNotFound"){
          this.metaService.updateCanonicalUrl(cleanUrl);
        }
      })

  }

  ngOnInit(): void {
  }
  private getCleanUrl(url: string): string {
    // Parse the URL using Angular's UrlSerializer
    const tree = this.urlSerializer.parse(url);
    // Serialize the tree's root to get the path
    const path = tree.root.children['primary']?.segments.map(segment => segment.path).join('/') || '';
    return '/' + path;
  }
}
