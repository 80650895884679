<nav [ngClass]="{'home_page' : isHomePage}">
  <ng-container >
    <div *web-template  class="webnav">
      <ul style="position: fixed;top: 0"  class="ul">
        <li class="dropdown">
          <button  class="button navButton" >Expertises </button>
          <div class="webMenuWrapper">
            <div class="webMenu">
              <ul>
                <li><a  routerLink="/conseil" href="/conseil" >Consulting</a></li>
                <li><a href="/factory" routerLink="/factory">Digital Factory</a></li>
              </ul>
            </div>
          </div>
        </li>
        <li class="dropdown">
          <button class="button navButton"  >Nous </button>
          <div class="webMenuWrapper">
            <div class="webMenu">
              <ul>
                <li>
                  <a href="/nous"   routerLink="/nous"  >Notre vision</a>
                </li>
                <li>
                  <a href="/nous" routerLink="/nous" fragment="equipe">Notre équipe</a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="dropdown">
          <button  class="button navButton" >Référence </button>
          <div class="webMenuWrapper">
            <div class="webMenu">
              <ul>
                <li>
                  <a href="/references" routerLink="/references">Succes stories</a>
                </li>
              </ul>
            </div>

          </div>
        </li>
        <li class="dropdown">
          <button class="button navButton" >Contact </button>
          <div class="webMenuWrapper">
            <div class="webMenu">
              <ul>
                <li>
                  <a  href="/contact"  routerLink="/contact" >Entreprises</a>
                </li>
                <li>
                  <a  href="/contact"  routerLink="/contact"  fragment="Candidats" >Candidats</a>
                </li>
              </ul>
            </div>

          </div>
        </li>
      </ul>
      <a style="position: absolute;top: 1rem;right: 2rem;" href="/" routerLink="/">
        <span >
          <img class="pointer" ngSrc="assets/images/simplyEfficient.png" alt="YellowIt logo" height="77" width="206">
        </span>
      </a>
    </div>
  </ng-container>
  <ng-container >
    <div *tablet-mobile-template class="menu" [ngStyle]="{'top': !isMobileMenuClosed ? '-100vh' : '0'}">
      <img class="img" (click)="HandleClick(MenuItem.BurgerMenu)" ngSrc="/assets/images/header/burgerOpen.png" alt="Burger_Menu"
           height="77" width="137">
      <img class="logo" ngSrc="assets/images/logoYellow.png" alt="Logo YellowIt" height="77" width="206">
      <ul>
        <li >
          <button (click)="HandleClick(MenuItem.Expertises);" class="button navButton navButtonMobile"  >Expertises </button>
          <div  class="sub_menu" [ngClass]="{'hover' : menuState.isExpertise}">
            <ul>
              <li ><a (click)="HandleClick('BURGER_MENU')" href="/conseil" routerLink="/conseil" >Consulting</a></li>
              <li> <a (click)="HandleClick('BURGER_MENU')" href="/factory" routerLink="/factory" > Digital Factory </a></li>
            </ul>
          </div>
        </li>
        <li >
          <button (click)="HandleClick(MenuItem.Nous)" class="button navButton navButtonMobile" >Nous </button>
          <div  class="sub_menu" [ngClass]="{'hover' : menuState.isNous}">
            <ul>
              <li>
                <a (click)="HandleClick('BURGER_MENU')" href="/nous" routerLink="/nous" >Notre vision</a>
              </li>
              <li>
                <a (click)="HandleClick('BURGER_MENU')" href="/nous" routerLink="/nous"  fragment="notre_equipe">Notre équipe</a>
              </li>
            </ul>
          </div>
        </li>
        <li >
          <button  (click)="HandleClick(MenuItem.References)" class="button navButton navButtonMobile" >Référence </button>
          <div  class="sub_menu" [ngClass]="{'hover' : menuState.isReference}">
            <ul>
              <li>
                <a (click)="HandleClick('BURGER_MENU')" href="/references" routerLink="references">Succes stories</a>
              </li>
            </ul>
          </div>
        </li>
        <li >
          <button (click)="HandleClick(MenuItem.Contact)" class="button navButton navButtonMobile" >Contact </button>
          <div  class="sub_menu" [ngClass]="{'hover' : menuState.isContact}">
            <ul>
              <li> <a href="/contact" routerLink="/contact" (click)="HandleClick('BURGER_MENU')" >Entreprises</a> </li>
              <li><a href="/contact" routerLink="/contact" fragment="form" (click)="HandleClick('BURGER_MENU')" >Candidats </a></li>
            </ul>
          </div>
        </li>

      </ul>
    </div>
    <div *tablet-mobile-template  class="image">
     @if (!isMobileMenuClosed){
       <img  (click)="HandleClick('BURGER_MENU')" ngSrc="/assets/images/header/headerClose.png" alt="Burger_Menu"
            height="59" width="115" priority>
     }
    </div>
  </ng-container>
</nav>

