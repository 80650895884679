import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import {isPlatformBrowser} from "@angular/common";
import {PLATFORM_ID} from "@angular/core";

document.addEventListener('DOMContentLoaded', () => {

  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));

  if (isPlatformBrowser(PLATFORM_ID)) {
    window.addEventListener('error', (event: ErrorEvent) => {
      if (event.message.includes('Failed to fetch dynamically imported module')) {
        // Perform a full page reload
        window.location.reload();
      }
    })
  }
  });
